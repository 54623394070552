// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMobile} from '@fortawesome/free-solid-svg-icons';

library.add(
  faFacebook,
  faYoutube,
  faEnvelope,
  faMobile,
  faInstagram
  // more icons go here
);
