import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BlackLogo from '../images/maxoutput.png';

class Home extends Component {
  render() {
    return(
    <div data-bs-spy="scroll" data-bs-target="#zznavbar-maxoutput" data-bs-offset="0" className="scrollspy-maxoutput" tabIndex="0"> 
      <main>
        <div id="about" className="px-3 py-3 page-content clearfix bg-white text-black text-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1><Link to="/"><img src={BlackLogo} className="img-fluid" alt="Max Output ~ Rock and pop band"></img></Link></h1>
                <p className="lead">Wiltshire based band playing an upbeat mix of rock and pop hits from across the last few decades.</p>
                <p>We're here to entertain you and get you dancing your socks off!</p>
                <p>You can expect well loved hits with a few unexpected numbers thrown in for good measure.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p>Deep Purple</p>
                <p>The Beatles</p>
                <p>ZZ Top</p>
                <p>Feeder</p>
                <p>Adele</p>
                <p>Bryan Adams</p>
                <p>Nirvana</p>
                <p>Katy Perry</p>
                <p>Queens of the Stone Age</p>
              </div>
              <div className="col-4">
                <p>Pink Floyd</p>
                <p>AC/DC</p>
                <p>Alice Cooper</p>
                <p>Queen</p>
                <p>The Rolling Stones</p>
                <p>Stereophonics</p>
                <p>Foo Fighters</p>
                <p>Soft Cell</p>
                <p>Thin Lizzy</p>
              </div>
              <div className="col-4">
                <p>U2</p>
                <p>Lady Gaga</p>
                <p>Genesis</p>
                <p>Muse</p>
                <p>The Cult</p>
                <p>Kings of Leon</p>
                <p>Guns &amp; Roses</p>
                <p>Maroon 5</p>
                <p>and many more ...</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    )
  }

}

export default Home;