import React from 'react';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
  return (
    <footer id="contact" className="px-3 py-3 page-content clearfix bg-black text-white">
      <div className="d-flex justify-content-center">
        <div className="p-2 bd-highlight"><span className="fa-li px-2"><FontAwesomeIcon icon="fa-solid fa-envelope" size="lg" /></span>info@maxoutput.co.uk</div>
        <div className="p-2 bd-highlight"><span className="fa-li px-2"><FontAwesomeIcon icon="fa-solid fa-mobile" size="lg" /></span>07977 047049</div>
        <div className="p-2 bd-highlight"><a href="https://www.facebook.com/maxoutputband"><FontAwesomeIcon icon="fa-brands fa-facebook" size="2x" color="white" /></a></div>
        <div className="p-2 bd-highlight"><a href="https://www.youtube.com/channel/UCvVNX8-TtbsM7_p0FP4_lxQ"><FontAwesomeIcon icon="fa-brands fa-youtube" size="2x" color="white" /></a></div>
        <div className="p-2 bd-highlight"><a href="https://instagram.com/maxoutputband?igshid=YmMyMTA2M2Y="><FontAwesomeIcon icon="fa-brands fa-instagram" size="2x" color="white" /></a></div>
      </div>
    </footer>
  )
}

export default Footer;