import React from 'react';
import BannerXS from '../images/band.jpg';

function Banner() {
  return(
    <div id="banner">
      <img src={BannerXS} className="cover" alt="The band"></img>
    </div>
  )
}

export default Banner;